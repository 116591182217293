export const INCREASE_SCORE = 'INCREASE_SCORE';
export const PASS_LEVEL = 'PASS_LEVEL';
export const END_GAME = 'END_GAME';
export const START_LEVEL = 'START_LEVEL';
export const RESTART_GAME = 'RESTART_GAME';
export const ABORT_GAME = 'ABORT_GAME';
export const TOGGLE_PAUSE = 'TOGGLE_PAUSE';
export const CHANGE_MODE = 'CHANGE_MODE';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const SHOW_INTRO = 'SHOW_INTRO';
export const SHOW_ANSWER_SCREEN = 'SHOW_ANSWER_SCREEN';
export const CHANGE_SCREEN = 'CHANGE_SCREEN';