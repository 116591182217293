import React from 'react';

const EMOJI_MAP = [
    0x1F600,
    0x1F602,
    0x1F609,
    0x1F60D,
    0x1F618,
    0x1F917,
    0x1F636,
    0x1F635,
    0x1F60E,
    0x1F913,
    0x1F641,
    0x1F62D,
    0x1F631,
    0x1F621,
    0x1F4A9,
    0x1F47B,
    0x1F47E,
    0x1F648,
    0x1F649,
    0x1F64A,
    0x1F941,
    0x1F4AB,
    0x1F44B,
    0x1F44C,
    0x1F3AC,
    0x1F440,
    0x1F44D,
    0x1F44E,
    0x1F44F,
    0x1F64F,
    0x1F485,
    0x1F4AA,
    0x1F476,
    0x1F469,
    0x1F475,
    0x1F645,
    0x1F481,
    0x1F937,
    0x1F934,
    0x1F470,
    0x1F385,
    0x1F41F,
    0x1F483,
    0x1F3C4,
    0x1F3CA,
    0x1F6B4,
    0x1F938,
    0x1F436,
    0x1F408,
    0x1F42F,
    0x1F434,
    0x1F42E,
    0x1F437,
    0x1F418,
    0x1F439,
    0x1F428,
    0x1F43C,
    0x1F423,
    0x1F989,
    0x1F422,
    0x1F419,
    0x1F490,
    0x1F332,
    0x1F335,
    0x1F340,
    0x1F34F,
    0x1F351,
    0x1F345,
    0x1F346,
    0x1F344,
    0x1F357,
    0x1F354,
    0x1F355,
    0x1F37F,
    0x1F366,
    0x1F382,
    0x1F37E,
    0x1F37B,
    0x1F3DD,
    0x1F3E1,
    0x1F3AA,
    0x1F682,
    0x1F692,
    0x1F694,
    0x1F698,
    0x1F6A6,
    0x1F6A4,
    0x1F48D,
    0x1F681,
    0x1F680,
    0x1F3A8,
    0x1F31E,
    0x1F3B2,
    0x1F308,
    0x1F3C0,
    0x1F383,
    0x1F525,
    0x1F389,
    0x1F381,
    0x1F3C6,
    0x1F407,
    0x1F40A,
    0X1F40B,
    0x1F40C,
    0x1F438,
    0x26BD,
    0x26F2,
    0x1F347,
    0x1F349,
    0x1F34A,
    0x1F34B,
    0x1F350,
    0x1F352,
    0x1F353,
    0x1F3B9,
    0x1F3BB,
    0x1F40D,
    0x1F411,
    0x1F413,
    0x1F41C,
    0x1F41D,
    0x1F444,
    0x1F453,
    0x1F455,
    0x1F456,
    0x1F457,
    0x1F45E,
    0x1F4D7,
    0x1F52A,
    0x1F577,
    0x1F691,
    0x1F68C,
    0x1F695,
    0x1F69B,
    0x1F69C,
    0x1F957,
    0x1F95A,
    0x1F95D,
    0x1F966,
    0x1F980,
    0x1F981,
    0x1F986,
    0x1F987,
    0x1F988,
    0x1F6B2,
    0x1F6CC,
    0x1F6E9,
    0x1F6F5,
    0x1F951,
    0x1F952,
    0x1F955,
    0x1F98B,
    0x1F98D,
    0x1F992,
    0x1F993,
    0x1F996,
    0x1F31B,
    0x1F327,
    0x1F33D
];

const emoji = (props) => (
    
    
    <span role="img" className={props.className} onClick={props.clickHandler}>
        <span>{String.fromCodePoint(EMOJI_MAP[props.num])}</span>
    </span>
);

export default emoji;