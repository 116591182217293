import React from 'react';

const audio = require.context("../../audio", true);

/* eslint no-eval: 0 */
// eslint-disable-next-line no-unused-vars
const KIDS_MAP_EN = [
    {text: "A mouse", question: "Where is the mouse?", hex: 0x1F401, fileName: "mouse"},
    {text: "A cow", question: "Where is the cow?", hex: 0x1F404, fileName: "cow"},
    {text: "A rabbit", question: "Where is the rabbit", hex: 0x1F407, fileName: "rabbit"},
    {text: "A cat", question: "Where is the cat?", hex: 0x1F408, fileName: "cat"},
    {text: "A crocodile", question: "Where is the crocodile?", hex: 0x1F40A, fileName: "crocodile"},
    {text: "A whale", question: "Where is the whale?", hex: 0X1F40B, fileName: "whale"},
    {text: "A snail", question: "Where is the snail?", hex: 0x1F40C, fileName: "snail"},
    {text: "An elephant", question: "Where is the elephant?", hex: 0x1F418, fileName: "elephant"},
    {text: "A fish", question: "Where is the fish?", hex: 0x1F41F, fileName: "fish"},
    {text: "A koala", question: "Where is the koala?", hex: 0x1F428, fileName: "koala"},
    {text: "A horse", question: "Where is the horse?", hex: 0x1F434, fileName: "horse"},
    {text: "A monkey", question: "Where is the monkey?", hex: 0x1F435, fileName: "monkey"},    
    {text: "A dog", question: "Where is the dog?", hex: 0x1F436, fileName: "dog"},
    {text: "A pig", question: "Where is the pig?", hex: 0x1F437, fileName: "pig"}, 
    {text: "A frog", question: "Where is the frog?", hex: 0x1F438, fileName: "frog"},
    {text: "A panda", question: "Where is the panda?", hex: 0x1F43C, fileName: "panda"},
    {text: "A football", question: "Where is the football?", hex: 0x26BD, fileName: "football"},
    {text: "A fountain", question: "Where is the fountain?", hex: 0x26F2, fileName: "fountain"},
    {text: "The rainbow", question: "Where is the rainbow?", hex: 0x1F308, fileName: "rainbow"},
    {text: "A tree", question: "Where is the tree?", hex: 0x1F332, fileName: "tree"},
    {text: "A flower", question: "Where is the flower?", hex: 0x1F338, fileName: "flower"},
    {text: "A tomato", question: "Where is the tomato?", hex: 0x1F345, fileName: "tomato"},
    {text: "An aubergine", question: "Where is the aubergine?", hex: 0x1F346, fileName: "aubergine"},
    {text: "Grapes", question: "Where are the grapes?", hex: 0x1F347, fileName: "grapes"},
    {text: "A watermelon", question: "Where is the watermelon?", hex: 0x1F349, fileName: "watermelon"},
    {text: "An orange", question: "Where is the orange?", hex: 0x1F34A, fileName: "orange"},
    {text: "A lemon", question: "Where is the lemon?", hex: 0x1F34B, fileName: "lemon"},
    {text: "A banana", question: "Where is the banana?", hex: 0x1F34C, fileName: "banana"},
    {text: "An apple", question: "Where is the apple?", hex: 0x1F34F, fileName: "apple"},
    {text: "A pear", question: "Where is the pear?", hex: 0x1F350, fileName: "pear"},
    {text: "Cherries", question: "Where are the cherries?", hex: 0x1F352, fileName: "cherries"},
    {text: "A strawberry", question: "Where is the strawberry?", hex: 0x1F353, fileName: "strawberry"},
    {text: "A pizza", question: "Where is the pizza?", hex: 0x1F355, fileName: "pizza"},
    {text: "An ice-cream", question: "Where is the ice-cream?", hex: 0x1F366, fileName: "icecream"},
    {text: "A present", question: "Where is the present?", hex: 0x1F381, fileName: "present"},
    {text: "A cake", question: "Where is the cake?", hex: 0x1F382, fileName: "cake"},
    {text: "A pumpkin", question: "Where is the pumpkin?", hex: 0x1F383, fileName: "pumpkin"},
    {text: "A balloon", question: "Where is the balloon?", hex: 0x1F388, fileName: "balloon"},
    {text: "A circus", question: "Where is the circus?", hex: 0x1F3AA, fileName: "circus"},
    {text: "A piano", question: "Where is the piano?", hex: 0x1F3B9, fileName: "piano"},
    {text: "A violin", question: "Where is the violin?", hex: 0x1F3BB, fileName: "violin"},
    {text: "A beach", question: "Where is the beach?", hex: 0x1F3D6, fileName: "beach"},
    {text: "A house", question: "Where is the house?", hex: 0x1F3E0, fileName: "house"},
    {text: "A snake", question: "Where is the snake?", hex: 0x1F40D, fileName: "snake"},
    {text: "A sheep", question: "Where is the sheep?", hex: 0x1F411, fileName: "sheep"},
    {text: "A hen", question: "Where is the hen?", hex: 0x1F413, fileName: "hen"},
    {text: "An octopus", question: "Where is the octopus?", hex: 0x1F419, fileName: "octopus"},
    {text: "An ant", question: "Where is the ant?", hex: 0x1F41C, fileName: "ant"},
    {text: "A bee", question: "Where is the bee?", hex: 0x1F41D, fileName: "bee"},
    {text: "A turtle", question: "Where is the turtle?", hex: 0x1F422, fileName: "turtle"},
    {text: "A chick", question: "Where is the chick?", hex: 0x1F425, fileName: "chick"},
    {text: "A camel", question: "Where is the camel?", hex: 0x1F42A, fileName: "camel"},
    {text: "A dolphin", question: "Where is the dolphin?", hex: 0x1F42C, fileName: "dolphin"},
    {text: "A tiger", question: "Where is the tiger?", hex: 0x1F42F, fileName: "tiger"},
    {text: "A bear", question: "Where is the bear?", hex: 0x1F43B, fileName: "bear"},
    {text: "A squirrel", question: "Where is the squirrel?", hex: 0x1F43F, fileName: "squirrel"},
    {text: "An eye", question: "Where is the eye?", hex: 0x1F441, fileName: "eye"},
    {text: "An ear", question: "Where is the ear?", hex: 0x1F442, fileName: "ear"},
    {text: "A nose", question: "Where is the nose?", hex: 0x1F443, fileName: "nose"},
    {text: "A mouth", question: "Where is the mouth?", hex: 0x1F444, fileName: "mouth"},
    {text: "A hand", question: "Where is the hand?", hex: 0x1F44B, fileName: "hand"},
    {text: "Glasses", question: "Where are the glasses?", hex: 0x1F453, fileName: "glasses"},
    {text: "A t-shirt", question: "Where is the t-shirt?", hex: 0x1F455, fileName: "tshirt"},
    {text: "Trousers", question: "Where are the trousers?", hex: 0x1F456, fileName: "trousers"},
    {text: "A dress", question: "Where is the dress?", hex: 0x1F457, fileName: "dress"},
    {text: "A shoe", question: "Where is the shoe?", hex: 0x1F45E, fileName: "shoe"},
    {text: "A boy", question: "Where is the boy?", hex: 0x1F466, fileName: "boy"},
    {text: "A girl", question: "Where is the girl?", hex: 0x1F467, fileName: "girl"},
    {text: "A book", question: "Where is the book?", hex: 0x1F4D7, fileName: "book"},
    {text: "A phone", question: "Where is the phone?", hex: 0x1F4F1, fileName: "phone"},
    {text: "A fire", question: "Where is the fire?", hex: 0x1F525, fileName: "fire"},
    {text: "A knife", question: "Where is the knife?", hex: 0x1F52A, fileName: "knife"},
    {text: "A spider", question: "Where is the spider?", hex: 0x1F577, fileName: "spider"},
    {text: "A TV", question: "Where is the TV?", hex: 0x1F5A5, fileName: "tv"},
    {text: "A helicopter", question: "Where is the helicopter?", hex: 0x1F681, fileName: "helicopter"},
    {text: "A train", question: "Where is the train?", hex: 0x1F686, fileName: "train"},
    {text: "A bus", question: "Where is the bus?", hex: 0x1F68C, fileName: "bus"},
    {text: "An ambulance", question: "Where is the ambulance?", hex: 0x1F691, fileName: "ambulance"},
    {text: "A taxi", question: "Where is the taxi?", hex: 0x1F695, fileName: "taxi"},
    {text: "A car", question: "Where is the car?", hex: 0x1F697, fileName: "car"},
    {text: "A truck", question: "Where is the truck?", hex: 0x1F69B, fileName: "truck"},
    {text: "A tractor", question: "Where is the tractor?", hex: 0x1F69C, fileName: "tractor"},
    {text: "A salad", question: "Where is the salad?", hex: 0x1F957, fileName: "salad"},
    {text: "An egg", question: "Where is the egg?", hex: 0x1F95A, fileName: "egg"},
    {text: "A kiwi", question: "Where is the kiwi?", hex: 0x1F95D, fileName: "kiwi"},
    {text: "A broccoli", question: "Where is the broccoli?", hex: 0x1F966, fileName: "broccoli"},
    {text: "A crab", question: "Where is the crab?", hex: 0x1F980, fileName: "crab"},
    {text: "A lion", question: "Where is the lion?", hex: 0x1F981, fileName: "lion"},
    {text: "A duck", question: "Where is the duck?", hex: 0x1F986, fileName: "duck"},
    {text: "A bat", question: "Where is the bat?", hex: 0x1F987, fileName: "bat"},
    {text: "A shark", question: "Where is the shark?", hex: 0x1F988, fileName: "shark"},
    {text: "A boat", question: "Where is the boat?", hex: 0x1F6A2, fileName: "boat"},
    {text: "A bike", question: "Where is the bike?", hex: 0x1F6B2, fileName: "bike"},
    {text: "A bed", question: "Where is the bed?", hex: 0x1F6CC, fileName: "bed"},
    {text: "A plane", question: "Where is the plane?", hex: 0x1F6E9, fileName: "plane"},
    {text: "A motorbike", question: "Where is the motorbike?", hex: 0x1F6F5, fileName: "motorbike"},
    {text: "Drums", question: "Where are the drums?", hex: 0x1F941, fileName: "drums"},
    {text: "An avocado", question: "Where is the avocado?", hex: 0x1F951, fileName: "avocado"},
    {text: "A cucumber", question: "Where is the cucumber?", hex: 0x1F952, fileName: "cucumber"},
    {text: "A carrot", question: "Where is the carrot?", hex: 0x1F955, fileName: "carrot"},
    {text: "A heart", question: "Where is the heart?", hex: 0x1F9E1, fileName: "heart"},
    {text: "An owl", question: "Where is the owl?", hex: 0x1F989, fileName: "owl"},
    {text: "A butterfly", question: "Where is the butterfly?", hex: 0x1F98B, fileName: "butterfly"},
    {text: "A gorilla", question: "Where is the gorilla?", hex: 0x1F98D, fileName: "gorilla"},
    {text: "A giraffe", question: "Where is the giraffe?", hex: 0x1F992, fileName: "giraffe"},
    {text: "A zebra", question: "Where is the zebra?", hex: 0x1F993, fileName: "zebra"},
    {text: "A dinosaur", question: "Where is the dinosaur?", hex: 0x1F996, fileName: "dinosaur"},
    {text: "The sun", question: "Where is the sun?", hex: 0x1F31E, fileName: "sun"},
    {text: "The moon", question: "Where is the moon?", hex: 0x1F31B, fileName: "moon"},
    {text: "A star", question: "Where is the star?", hex: 0x1F31F, fileName: "star"},
    {text: "The rain", question: "Where is the rain?", hex: 0x1F327, fileName: "rain"},
    {text: "A police car", question: "Where is the police car?", hex: 0x1F694, fileName: "policecar"},
    {text: "A fire engine", question: "Where is the fire engine?", hex: 0x1F692, fileName: "fireengine"},
    {text: "A tram", question: "Where is the tram?", hex: 0x1F683, fileName: "tram"},
    {text: "A rocket", question: "Where is the rocket?", hex: 0x1F680, fileName: "rocket"},
    {text: "A snowman", question: "Where is the snowman?", hex: 0x26C4, fileName: "snowman"},
    {text: "A mountain", question: "Where is the mountain?", hex: 0x1f3d4, fileName: "mountain"},
    {text: "A wave", question: "Where is the wave?", hex: 0x1F30A, fileName: "wave"},
    {text: "A pineapple", question: "Where is the pineapple?", hex: 0x1F34D, fileName: "pineapple"},
    {text: "A cookie", question: "Where is the cookie?", hex: 0x1F36A, fileName: "cookie"},
    {text: "A castle", question: "Where is the castle?", hex: 0x1F3F0, fileName: "castle"},
    {text: "A deer", question: "Where is the deer?", hex: 0x1F98C, fileName: "deer"},
    {text: "A laptop", question: "Where is the laptop?", hex: 0x1F4BB, fileName: "laptop"},
    {text: "A trumpet", question: "Where is the trumpet?", hex: 0x1F3BA, fileName: "trumpet"},
    {text: "A ladybug", question: "Where is the ladybug?", hex: 0x1F41E, fileName: "ladybug"},
    {text: "Corn", question: "Where is the corn?", hex: 0x1F33D, fileName: "corn"},
    {text: "A cup of coffee", question: "Where is the cup of coffee?", hex: 0x2615, fileName: "coffee"},
    {text: "A tent", question: "Where is the tent?", hex: 0x26FA, fileName: "tent"},
    {text: "A police officer", question: "Where is the police officer?", hex: 0x1F46E, fileName: "police"},
    {text: "A man", question: "Where is the man?", hex: 0x1F468, fileName: "man"},
    {text: "A woman", question: "Where is the woman?", hex: 0x1F469, fileName: "woman"},
    {text: "A baby", question: "Where is the baby?", hex: 0x1F476, fileName: "baby"},
    {text: "A robot", question: "Where is the robot?", hex: 0x1F916, fileName: "robot"},
    {text: "A clown", question: "Where is the clown?", hex: 0x1F921, fileName: "clown"},
    {text: "A tongue", question: "Where is the tongue?", hex: 0x1F445, fileName: "tongue"},
    {text: "An arm", question: "Where is the arm?", hex: 0x1F4AA, fileName: "arm"},
    {text: "A bone", question: "Where is the bone?", hex: 0x1F9B4, fileName: "bone"},
    {text: "A leg", question: "Where is the leg?", hex: 0x1F9B5, fileName: "leg"},
    {text: "A foot", question: "Where is the foot?", hex: 0x1F9B6, fileName: "foot"},
    {text: "A tooth", question: "Where is the tooth?", hex: 0x1F9B7, fileName: "tooth"},
    {text: "A wheelchair", question: "Where is the wheelchair?", hex: 0x1F9BD, fileName: "wheelchair"},
    {text: "A ring", question: "Where is the ring?", hex: 0x1F48D, fileName: "ring"},
    {text: "A pen", question: "Where is the pen?", hex: 0x1F58A, fileName: "pen"},
    {text: "A package", question: "Where is the package?", hex: 0x1F4E6, fileName: "package"},
    {text: "A clock", question: "Where is the clock?", hex: 0x1F570, fileName: "clock"},
    {text: "A princess", question: "Where is the princess?", hex: 0x1F478, fileName: "princess"},
    {text: "A painting", question: "Where is the painting?", hex: 0x1F5BC, fileName: "painting"},
    {text: "A bin", question: "Where is the bin?", hex: 0x1F5D1, fileName: "bin"},
    {text: "A volcano", question: "Where is the volcano?", hex: 0x1F30B, fileName: "volcano"},
    {text: "A hotel", question: "Where is the hotel?", hex: 0x1F3E8, fileName: "hotel"},
    {text: "A hospital", question: "Where is the hospital?", hex: 0x1F3E5, fileName: "hospital"},
    {text: "A stadium", question: "Where is the stadium?", hex: 0x1F3DF, fileName: "stadium"},
    {text: "A toilet", question: "Where is the toilet?", hex: 0x1F6BD, fileName: "toilet"},
    {text: "A bathtub", question: "Where is the bathtub?", hex: 0x1F6C1, fileName: "bathtub"},
    {text: "Rollerskates", question: "Where are the rollerskates?", hex: 0x1F6FC, fileName: "rollerskates"},
    {text: "A scooter", question: "Where is the scooter?", hex: 0x1F6F4, fileName: "scooter"},
    {text: "A goat", question: "Where is the goat?", hex: 0x1F410, fileName: "goat"},
    {text: "A wild pig", question: "Where is the wild pig?", hex: 0x1F417, fileName: "wildpig"},
    {text: "An eagle", question: "Where is the eagle?", hex: 0x1F985, fileName: "eagle"},
    {text: "A rhino", question: "Where is the rhino?", hex: 0x1F98F, fileName: "rhino"},
    {text: "A peacock", question: "Where is the peacock?", hex: 0x1F99A, fileName: "peacock"},
    {text: "A hippo", question: "Where is the hippo?", hex: 0x1F99B, fileName: "hippo"},
    {text: "A parrot", question: "Where is the parrot?", hex: 0x1F99C, fileName: "parrot"},
    {text: "A mosquito", question: "Where is the mosquito?", hex: 0x1F99F, fileName: "mosquito"},
    {text: "A swan", question: "Where is the swan?", hex: 0x1F9A2, fileName: "swan"},
    {text: "A seal", question: "Where is the seal?", hex: 0x1F9AD, fileName: "seal"},
    {text: "A burger", question: "Where is the burger?", hex: 0x1F354, fileName: "burger"},
    {text: "A loaf of bread", question: "Where is the loaf of bread?", hex: 0x1F956, fileName: "bread"},
    {text: "Peanuts", question: "Where are the peanuts?", hex: 0x1F95C, fileName: "peanuts"},
    {text: "Cheese", question: "Where is the cheese?", hex: 0x1F9C0, fileName: "cheese"},
    {text: "A garlic", question: "Where is the garlic?", hex: 0x1F9C4, fileName: "garlic"},
    {text: "An onion", question: "Where is the onion?", hex: 0x1F9C5, fileName: "onion"},
    {text: "A spoon", question: "Where is the spoon?", hex: 0x1F944, fileName: "spoon"},
    {text: "A cactus", question: "Where is the cactus?", hex: 0x1F335, fileName: "cactus"},
    {text: "A mushroom", question: "Where is the mushroom?", hex: 0x1F344, fileName: "mushroom"},
    {text: "A peach", question: "Where is the peach?", hex: 0x1F351, fileName: "peach"},
    {text: "A basketball", question: "Where is the basketball?", hex: 0x1F3C0, fileName: "basketball"},
    {text: "A tennis ball", question: "Where is the tennis ball?", hex: 0x1F3BE, fileName: "tennis"},
    {text: "A medal", question: "Where is the medal?", hex: 0x1F3C5, fileName: "medal"},
    {text: "An umbrella", question: "Where is the umbrella?", hex: 0x2614, fileName: "umbrella"},
    {text: "A bikini", question: "Where is the bikini?", hex: 0x1F459, fileName: "bikini"},
    {text: "Socks", question: "Where are the socks?", hex: 0x1F9E6, fileName: "socks"},
    {text: "A cap", question: "Where is the cap?", hex: 0x1F9E2, fileName: "cap"},
    {text: "Gloves", question: "Where are the gloves?", hex: 0x1F9E4, fileName: "gloves"},
    {text: "A scarf", question: "Where is the scarf?", hex: 0x1F9E3, fileName: "scarf"},
    {text: "A handbag", question: "Where is the handbag?", hex: 0x1F45C, fileName: "handbag"},
    {text: "A saxophone", question: "Where is the saxophone?", hex: 0x1F3B7, fileName: "saxophone"},
    {text: "A key", question: "Where is the key?", hex: 0x1F511, fileName: "key"},
    {text: "A hammer", question: "Where is the hammer?", hex: 0x1F528, fileName: "hammer"},
    {text: "A bell", question: "Where is the bell?", hex: 0x1F514, fileName: "bell"},
    {text: "A broom", question: "Where is the broom?", hex: 0x1F9F9, fileName: "broom"},
    {text: "A candle", question: "Where is the candle?", hex: 0x1F56F, fileName: "candle"},
    {text: "A lightbulb", question: "Where is the lightbulb?", hex: 0x1F4A1, fileName: "lightbulb"},
    {text: "A door", question: "Where is the door?", hex: 0x1F6AA, fileName: "door"}
];

// eslint-disable-next-line no-unused-vars
const KIDS_MAP_ES = [
    {text: "Un ratón", question: "¿Dónde está el ratón?", hex: 0x1F401, fileName: "mouse"},
    {text: "Una vaca", question: "¿Dónde está la vaca?", hex: 0x1F404, fileName: "cow"},
    {text: "Un conejo", question: "¿Dónde está el conejo?", hex: 0x1F407, fileName: "rabbit"},
    {text: "Un gato", question: "¿Dónde está el gato?", hex: 0x1F408, fileName: "cat"},
    {text: "Un cocodrilo", question: "¿Dónde está el cocodrilo?", hex: 0x1F40A, fileName: "crocodile"},
    {text: "Una ballena", question: "¿Dónde está la ballena?", hex: 0X1F40B, fileName: "whale"},
    {text: "Un caracol", question: "¿Dónde está el caracol?", hex: 0x1F40C, fileName: "snail"},
    {text: "Un elefante", question: "¿Dónde está el elefante?", hex: 0x1F418, fileName: "elephant"},
    {text: "Un pez", question: "¿Dónde está el pez?", hex: 0x1F41F, fileName: "fish"},
    {text: "Un koala", question: "¿Dónde está el koala", hex: 0x1F428, fileName: "koala"},
    {text: "Un caballo", question: "¿Dónde está el caballo?", hex: 0x1F434, fileName: "horse"},
    {text: "Un mono", question: "¿Dónde está el mono?", hex: 0x1F435, fileName: "monkey"},    
    {text: "Un perro", question: "¿Dónde está un perro?", hex: 0x1F436, fileName: "dog"},
    {text: "Un cerdo", question: "¿Dónde está el cerdo?", hex: 0x1F437, fileName: "pig"}, 
    {text: "Una rana", question: "¿Dónde está la rana?", hex: 0x1F438, fileName: "frog"},
    {text: "Un oso panda", question: "¿Dónde está el oso panda?", hex: 0x1F43C, fileName: "panda"},
    {text: "Un balón de fútbol", question: "¿Dónde está el balón de fútbol?", hex: 0x26BD, fileName: "football"},
    {text: "Una fuente", question: "¿Dónde está la fuente?", hex: 0x26F2, fileName: "fountain"},
    {text: "Un arco iris", question: "¿Dónde está el arco iris?", hex: 0x1F308, fileName: "rainbow"},
    {text: "Un árbol", question: "¿Dónde está el árbol?", hex: 0x1F332, fileName: "tree"},
    {text: "Una flor", question: "¿Dónde está la flor?", hex: 0x1F338, fileName: "flower"},
    {text: "Un tomate", question: "¿Dónde está el tomate?", hex: 0x1F345, fileName: "tomato"},
    {text: "Una berenjena", question: "¿Dónde está la berenjena?", hex: 0x1F346, fileName: "aubergine"},
    {text: "Unas uvas", question: "¿Dónde están las uvas?", hex: 0x1F347, fileName: "grapes"},
    {text: "Una sandía", question: "¿Dónde está la sandía?", hex: 0x1F349, fileName: "watermelon"},
    {text: "Una naranja", question: "¿Dónde está la naranja?", hex: 0x1F34A, fileName: "orange"},
    {text: "Un limón", question: "¿Dónde está el limón?", hex: 0x1F34B, fileName: "lemon"},
    {text: "Un plátano", question: "¿Dónde está el plátano?", hex: 0x1F34C, fileName: "banana"},
    {text: "Una manzana", question: "¿Dónde está la manzana?", hex: 0x1F34F, fileName: "apple"},
    {text: "Una pera", question: "¿Dónde está la pera?", hex: 0x1F350, fileName: "pear"},
    {text: "Unas cerezas", question: "¿Dónde están las cerezas?", hex: 0x1F352, fileName: "cherries"},
    {text: "Una fresa", question: "¿Dónde está la fresa?", hex: 0x1F353, fileName: "strawberry"},
    {text: "Una pizza", question: "¿Dónde está la pizza?", hex: 0x1F355, fileName: "pizza"},
    {text: "Un helado", question: "¿Dónde está el helado?", hex: 0x1F366, fileName: "icecream"},
    {text: "Un regalo", question: "¿Dónde está el regalo?", hex: 0x1F381, fileName: "present"},
    {text: "Una tarta", question: "¿Dónde está la tarta?", hex: 0x1F382, fileName: "cake"},
    {text: "Una calabaza", question: "¿Dónde está la calabaza?", hex: 0x1F383, fileName: "pumpkin"},
    {text: "Un globo", question: "¿Dónde está el globo?", hex: 0x1F388, fileName: "balloon"},
    {text: "Un circo", question: "¿Dónde está el circo?", hex: 0x1F3AA, fileName: "circus"},
    {text: "Un piano", question: "¿Dónde está el piano?", hex: 0x1F3B9, fileName: "piano"},
    {text: "Un violín", question: "¿Dónde está el violín?", hex: 0x1F3BB, fileName: "violin"},
    {text: "Una playa", question: "¿Dónde está la playa?", hex: 0x1F3D6, fileName: "beach"},
    {text: "Una casa", question: "¿Dónde está la casa?", hex: 0x1F3E0, fileName: "house"},
    {text: "Una serpiente", question: "¿Dónde está la serpiente?", hex: 0x1F40D, fileName: "snake"},
    {text: "Una oveja", question: "¿Dónde está la oveja?", hex: 0x1F411, fileName: "sheep"},
    {text: "Una gallina", question: "¿Dónde está la gallina?", hex: 0x1F413, fileName: "hen"},
    {text: "Un pulpo", question: "¿Dónde está el pulpo?", hex: 0x1F419, fileName: "octopus"},
    {text: "Una hormiga", question: "¿Dónde está la hormiga?", hex: 0x1F41C, fileName: "ant"},
    {text: "Una abeja", question: "¿Dónde está la abeja?", hex: 0x1F41D, fileName: "bee"},
    {text: "Una tortuga", question: "¿Dónde está la tortuga?", hex: 0x1F422, fileName: "turtle"},
    {text: "Un pollito", question: "¿Dónde está el pollito?", hex: 0x1F425, fileName: "chick"},
    {text: "Un camello", question: "¿Dónde está el camello?", hex: 0x1F42A, fileName: "camel"},
    {text: "Un delfín", question: "¿Dónde está el delfín?", hex: 0x1F42C, fileName: "dolphin"},
    {text: "Un tigre", question: "¿Dónde está el tigre?", hex: 0x1F42F, fileName: "tiger"},
    {text: "Un oso", question: "¿Dónde está el oso?", hex: 0x1F43B, fileName: "bear"},
    {text: "Una ardilla", question: "¿Dónde está la ardilla?", hex: 0x1F43F, fileName: "squirrel"},
    {text: "Un ojo", question: "¿Dónde está el ojo?", hex: 0x1F441, fileName: "eye"},
    {text: "Una oreja", question: "¿Dónde está la oreja?", hex: 0x1F442, fileName: "ear"},
    {text: "Una nariz", question: "¿Dónde está la nariz?", hex: 0x1F443, fileName: "nose"},
    {text: "Una boca", question: "¿Dónde está la boca?", hex: 0x1F444, fileName: "mouth"},
    {text: "Una mano", question: "¿Dónde está la mano?", hex: 0x1F44B, fileName: "hand"},
    {text: "Unas gafas", question: "¿Dónde están las gafas?", hex: 0x1F453, fileName: "glasses"},
    {text: "Una camiseta", question: "¿Dónde está la camiseta?", hex: 0x1F455, fileName: "tshirt"},
    {text: "Unos pantalones", question: "¿Dónde están los pantalones?", hex: 0x1F456, fileName: "trousers"},
    {text: "Un vestido", question: "¿Dónde está el vestido?", hex: 0x1F457, fileName: "dress"},
    {text: "Un zapato", question: "¿Dónde está el zapato?", hex: 0x1F45E, fileName: "shoe"},
    {text: "Un niño", question: "¿Dónde está el niño?", hex: 0x1F466, fileName: "boy"},
    {text: "Una niña", question: "¿Dónde está la niña?", hex: 0x1F467, fileName: "girl"},
    {text: "Un libro", question: "¿Dónde está el libro?", hex: 0x1F4D7, fileName: "book"},
    {text: "Un teléfono", question: "¿Dónde está el teléfono?", hex: 0x1F4F1, fileName: "phone"},
    {text: "El fuego", question: "¿Dónde está el fuego?", hex: 0x1F525, fileName: "fire"},
    {text: "Un cuchillo", question: "¿Dónde está el cuchillo?", hex: 0x1F52A, fileName: "knife"},
    {text: "Una araña", question: "¿Dónde está la araña?", hex: 0x1F577, fileName: "spider"},
    {text: "Una televisión", question: "¿Dónde está la televisión?", hex: 0x1F5A5, fileName: "tv"},
    {text: "Un helicóptero", question: "¿Dónde está el helicóptero?", hex: 0x1F681, fileName: "helicopter"},
    {text: "Un tren", question: "¿Dónde está el tren?", hex: 0x1F686, fileName: "train"},
    {text: "Un autobús", question: "¿Dónde está el autobús?", hex: 0x1F68C, fileName: "bus"},
    {text: "Una ambulancia", question: "¿Dónde está la ambulancia?", hex: 0x1F691, fileName: "ambulance"},
    {text: "Un taxi", question: "¿Dónde está el taxi?", hex: 0x1F695, fileName: "taxi"},
    {text: "Un coche", question: "¿Dónde está el coche?", hex: 0x1F697, fileName: "car"},
    {text: "Un camión", question: "¿Dónde está el camión?", hex: 0x1F69B, fileName: "truck"},
    {text: "Un tractor", question: "¿Dónde está el tractor?", hex: 0x1F69C, fileName: "tractor"},
    {text: "Una ensalada", question: "¿Dónde está la ensalada?", hex: 0x1F957, fileName: "salad"},
    {text: "Un huevo", question: "¿Dónde está el huevo?", hex: 0x1F95A, fileName: "egg"},
    {text: "Un kiwi", question: "¿Dónde está el kiwi?", hex: 0x1F95D, fileName: "kiwi"},
    {text: "Un brócoli", question: "¿Dónde está el brócoli?", hex: 0x1F966, fileName: "broccoli"},
    {text: "Un cangrejo", question: "¿Dónde está el cangrejo?", hex: 0x1F980, fileName: "crab"},
    {text: "Un león", question: "¿Dónde está el león?", hex: 0x1F981, fileName: "lion"},
    {text: "Un pato", question: "¿Dónde está el pato?", hex: 0x1F986, fileName: "duck"},
    {text: "Un murciélago", question: "¿Dónde está el murciélago?", hex: 0x1F987, fileName: "bat"},
    {text: "Un tiburón", question: "¿Dónde está el tiburón?", hex: 0x1F988, fileName: "shark"},
    {text: "Un barco", question: "¿Dónde está el barco?", hex: 0x1F6A2, fileName: "boat"},
    {text: "Una bicicleta", question: "¿Dónde está la bicicleta?", hex: 0x1F6B2, fileName: "bike"},
    {text: "Una cama", question: "¿Dónde está la cama?", hex: 0x1F6CC, fileName: "bed"},
    {text: "Un avión", question: "¿Dónde está el avión?", hex: 0x1F6E9, fileName: "plane"},
    {text: "Una moto", question: "¿Dónde está la moto?", hex: 0x1F6F5, fileName: "motorbike"},
    {text: "Un tambor", question: "¿Dónde está el tambor?", hex: 0x1F941, fileName: "drums"},
    {text: "Un aguacate", question: "¿Dónde está el aguacate?", hex: 0x1F951, fileName: "avocado"},
    {text: "Un pepino", question: "¿Dónde está el pepino?", hex: 0x1F952, fileName: "cucumber"},
    {text: "Una zanahoria", question: "¿Dónde está la zanahoria?", hex: 0x1F955, fileName: "carrot"},
    {text: "Un corazón", question: "¿Dónde está el corazón?", hex: 0x1F9E1, fileName: "heart"},
    {text: "Un búho", question: "¿Dónde está el búho?", hex: 0x1F989, fileName: "owl"},
    {text: "Una mariposa", question: "¿Dónde está la mariposa?", hex: 0x1F98B, fileName: "butterfly"},
    {text: "Un gorila", question: "¿Dónde está el gorila?", hex: 0x1F98D, fileName: "gorilla"},
    {text: "Una jirafa", question: "¿Dónde está la jirafa?", hex: 0x1F992, fileName: "giraffe"},
    {text: "Una cebra", question: "¿Dónde está la cebra?", hex: 0x1F993, fileName: "zebra"},
    {text: "Un dinosaurio", question: "¿Dónde está el dinosaurio?", hex: 0x1F996, fileName: "dinosaur"},
    {text: "El sol", question: "¿Dónde está el sol?", hex: 0x1F31E, fileName: "sun"},
    {text: "La luna", question: "¿Dónde está la luna?", hex: 0x1F31B, fileName: "moon"},
    {text: "Una estrella", question: "¿Dónde está la estrella?", hex: 0x1F31F, fileName: "star"},
    {text: "La lluvia", question: "¿Dónde está la lluvia?", hex: 0x1F327, fileName: "rain"},
    {text: "Un coche de policía", question: "¿Dónde está el coche de policía?", hex: 0x1F694, fileName: "policecar"},
    {text: "Un camión de bomberos", question: "¿Donde está el camión de bomberos?", hex: 0x1F692, fileName: "fireengine"},
    {text: "Un tranvía", question: "¿Dónde está el tranvía?", hex: 0x1F683, fileName: "tram"},
    {text: "Un cohete", question: "¿Dónde está el cohete?", hex: 0x1F680, fileName: "rocket"},
    {text: "Un muñeco de nieve", question: "¿Dónde está el muñeco de nieve?", hex: 0x26C4, fileName: "snowman"},
    {text: "Una montaña", question: "¿Dónde está la montaña?", hex: 0x1f3d4, fileName: "mountain"},
    {text: "Una ola", question: "¿Dónde está la ola?", hex: 0x1F30A, fileName: "wave"},
    {text: "Una piña", question: "¿Dónde está la piña?", hex: 0x1F34D, fileName: "pineapple"},
    {text: "Una galleta", question: "¿Dónde está la galleta?", hex: 0x1F36A, fileName: "cookie"},
    {text: "Un castillo", question: "¿Dónde está el castillo?", hex: 0x1F3F0, fileName: "castle"},
    {text: "Un ciervo", question: "¿Dónde está el ciervo?", hex: 0x1F98C, fileName: "deer"},
    {text: "Un portátil", question: "¿Dónde está el portátil?", hex: 0x1F4BB, fileName: "laptop"},
    {text: "Una trompeta", question: "¿Dónde está la trompeta?", hex: 0x1F3BA, fileName: "trumpet"},
    {text: "Una mariquita", question: "¿Dónde está la mariquita?", hex: 0x1F41E, fileName: "ladybug"},
    {text: "Maíz", question: "¿Dónde está el maíz?", hex: 0x1F33D, fileName: "corn"},
    {text: "Un café", question: "¿Dónde está el café?", hex: 0x2615, fileName: "coffee"},
    {text: "Una tienda de campaña", question: "¿Dónde está la tienda de campaña?", hex: 0x26FA, fileName: "tent"},
    {text: "Una agente de policía", question: "¿Dónde está la agente de policía?", hex: 0x1F46E, fileName: "police"},
    {text: "Un hombre", question: "¿Dónde está el hombre?", hex: 0x1F468, fileName: "man"},
    {text: "Una mujer", question: "¿Dónde está la mujer?", hex: 0x1F469, fileName: "woman"},
    {text: "Un bebé", question: "¿Dónde está el bebé?", hex: 0x1F476, fileName: "baby"},
    {text: "Un robot", question: "¿Dónde está el robot?", hex: 0x1F916, fileName: "robot"},
    {text: "Un payaso", question: "¿Dónde está el payaso?", hex: 0x1F921, fileName: "clown"},
    {text: "Una lengua", question: "¿Dónde está la lengua?", hex: 0x1F445, fileName: "tongue"},
    {text: "Un brazo", question: "¿Dónde está el brazo?", hex: 0x1F4AA, fileName: "arm"},
    {text: "Un hueso", question: "¿Dónde está el hueso?", hex: 0x1F9B4, fileName: "bone"},
    {text: "Una pierna", question: "¿Dónde está la pierna?", hex: 0x1F9B5, fileName: "leg"},
    {text: "Un pie", question: "¿Dónde está el pie?", hex: 0x1F9B6, fileName: "foot"},
    {text: "Un diente", question: "¿Dónde está el diente?", hex: 0x1F9B7, fileName: "tooth"},
    {text: "Una silla de ruedas", question: "¿Dónde está la silla de ruedas?", hex: 0x1F9BD, fileName: "wheelchair"},
    {text: "Un anillo", question: "¿Dónde está el anillo?", hex: 0x1F48D, fileName: "ring"},
    {text: "Un bolígrafo", question: "¿Dónde está el bolígrafo?", hex: 0x1F58A, fileName: "pen"},
    {text: "Un paquete", question: "¿Dónde está el paquete?", hex: 0x1F4E6, fileName: "package"},
    {text: "Un reloj", question: "¿Dónde está el reloj?", hex: 0x1F570, fileName: "clock"},
    {text: "Una princesa", question: "¿Dónde está la princesa?", hex: 0x1F478, fileName: "princess"},
    {text: "Un cuadro", question: "¿Dónde está el cuadro?", hex: 0x1F5BC, fileName: "painting"},
    {text: "Una papelera", question: "¿Dónde está la papelera?", hex: 0x1F5D1, fileName: "bin"},
    {text: "Un volcán", question: "¿Dónde está el volcán?", hex: 0x1F30B, fileName: "vulcano"},
    {text: "Un hotel", question: "¿Dónde está el hotel?", hex: 0x1F3E8, fileName: "hotel"},
    {text: "Un hospital", question: "¿Dónde está el hospital?", hex: 0x1F3E5, fileName: "hospital"},
    {text: "Un estadio", question: "¿Dónde está el estadio?", hex: 0x1F3DF, fileName: "stadium"},
    {text: "Un váter", question: "¿Dónde está el váter?", hex: 0x1F6BD, fileName: "toilet"},
    {text: "Una bañera", question: "¿Dónde está la bañera?", hex: 0x1F6C1, fileName: "bathtub"},
    {text: "Unos patines", question: "¿Dónde están los patines?", hex: 0x1F6FC, fileName: "rollerskates"},
    {text: "Un patinete", question: "¿Dónde está el patinete?", hex: 0x1F6F4, fileName: "scooter"},
    {text: "Una cabra", question: "¿Dónde está la cabra?", hex: 0x1F410, fileName: "goat"},
    {text: "Un jabalí", question: "¿Dónde está el jabalí?", hex: 0x1F417, fileName: "wildpig"},
    {text: "Un águila", question: "¿Dónde está el águila?", hex: 0x1F985, fileName: "eagle"},
    {text: "Un rinoceronte", question: "¿Dónde está el rinoceronte?", hex: 0x1F98F, fileName: "rhino"},
    {text: "Un pavo real", question: "¿Dónde está el pavo real?", hex: 0x1F99A, fileName: "peacock"},
    {text: "Un hipopótamo", question: "¿Dónde está el hipopótamo?", hex: 0x1F99B, fileName: "hippo"},
    {text: "Un loro", question: "¿Dónde está el loro?", hex: 0x1F99C, fileName: "parrot"},
    {text: "Un mosquito", question: "¿Dónde está el mosquito?", hex: 0x1F99F, fileName: "mosquito"},
    {text: "Un cisne", question: "¿Dónde está el cisne?", hex: 0x1F9A2, fileName: "swan"},
    {text: "Una foca", question: "¿Dónde está la foca?", hex: 0x1F9AD, fileName: "seal"},
    {text: "Una hamburguesa", question: "¿Dónde está la hamburguesa?", hex: 0x1F354, fileName: "burger"},
    {text: "Una barra de pan", question: "¿Dónde está la barra de pan?", hex: 0x1F956, fileName: "bread"},
    {text: "Cacahuetes", question: "¿Dónde están los cacahuetes?", hex: 0x1F95C, fileName: "peanuts"},
    {text: "Queso", question: "¿Dónde está el queso?", hex: 0x1F9C0, fileName: "cheese"},
    {text: "Un ajo", question: "¿Dónde está el ajo?", hex: 0x1F9C4, fileName: "garlic"},
    {text: "Una cebolla", question: "¿Dónde está la cebolla?", hex: 0x1F9C5, fileName: "onion"},
    {text: "Una cuchara", question: "¿Dónde está la cuchara?", hex: 0x1F944, fileName: "spoon"},
    {text: "Un cáctus", question: "¿Dónde está el cáctus?", hex: 0x1F335, fileName: "cactus"},
    {text: "Una seta", question: "¿Dónde está la seta?", hex: 0x1F344, fileName: "mushroom"},
    {text: "Un melocotón", question: "¿Dónde está el melocotón?", hex: 0x1F351, fileName: "peach"},
    {text: "Un balón de baloncesto", question: "¿Dónde está el balón de baloncesto?", hex: 0x1F3C0, fileName: "basketball"},
    {text: "Una pelota de ténis", question: "¿Dónde está la pelota de ténis?", hex: 0x1F3BE, fileName: "tennis"},
    {text: "Una medalla", question: "¿Dónde está la medalla?", hex: 0x1F3C5, fileName: "medal"},
    {text: "Un paragüas", question: "¿Dónde está el paragüas?", hex: 0x2614, fileName: "umbrella"},
    {text: "Un bikini", question: "¿Dónde está el bikini?", hex: 0x1F459, fileName: "bikini"},
    {text: "Unos calcetines", question: "¿Dónde están los calcetines?", hex: 0x1F9E6, fileName: "socks"},
    {text: "Una gorra", question: "¿Dónde está la gorra?", hex: 0x1F9E2, fileName: "cap"},
    {text: "Unos guantes", question: "¿Dónde están los guantes?", hex: 0x1F9E4, fileName: "gloves"},
    {text: "Una bufanda", question: "¿Dónde está la bufanda?", hex: 0x1F9E3, fileName: "scarf"},
    {text: "Un bolso", question: "¿Dónde está el bolso?", hex: 0x1F45C, fileName: "handbag"},
    {text: "Un saxofón", question: "¿Dónde está el saxofón?", hex: 0x1F3B7, fileName: "saxophone"},
    {text: "Una llave", question: "¿Dónde está la llave?", hex: 0x1F511, fileName: "key"},
    {text: "Un martillo", question: "¿Dónde está el martillo?", hex: 0x1F528, fileName: "hammer"},
    {text: "Una campana", question: "¿Dónde está la campana?", hex: 0x1F514, fileName: "bell"},
    {text: "Una escoba", question: "¿Dónde está la escoba?", hex: 0x1F9F9, fileName: "broom"},
    {text: "Una vela", question: "¿Dónde está la vela?", hex: 0x1F56F, fileName: "candle"},
    {text: "Una bombilla", question: "¿Dónde está la bombilla?", hex: 0x1f4a1, fileName: "lightbulb"},
    {text: "Una puerta", question: "¿Dónde está la puerta?", hex: 0x1F6AA, fileName: "door"}
];

// eslint-disable-next-line no-unused-vars
const KIDS_MAP_FR = [
    {text: "Une souris", question: "Où est la souris?", hex: 0x1F401, fileName: "mouse"},
    {text: "Une vache", question: "Où est la vache?", hex: 0x1F404, fileName: "cow"},
    {text: "Un lapin", question: "Où est le lapin?", hex: 0x1F407, fileName: "rabbit"},
    {text: "Un chat", question: "Où est le chat?", hex: 0x1F408, fileName: "cat"},
    {text: "Un crocodile", question: "Où est le crocodile?", hex: 0x1F40A, fileName: "crocodile"},
    {text: "Une baleine", question: "Où est la baleine?", hex: 0X1F40B, fileName: "whale"},
    {text: "Un escargot", question: "Où est l'escargot?", hex: 0x1F40C, fileName: "snail"},
    {text: "Un éléphant", question: "Où est l'éléphant?", hex: 0x1F418, fileName: "elephant"},
    {text: "Un poisson", question: "Où est le poisson?", hex: 0x1F41F, fileName: "fish"},
    {text: "Un koala", question: "Où est le koala?", hex: 0x1F428, fileName: "koala"},
    {text: "Un cheval", question: "Où est le cheval?", hex: 0x1F434, fileName: "horse"},
    {text: "Un singe", question: "Où est le singe?", hex: 0x1F435, fileName: "monkey"},
    {text: "Un chien", question: "Où est le chien?", hex: 0x1F436, fileName: "dog"},
    {text: "Un cochon", question: "Où est le cochon?", hex: 0x1F437, fileName: "pig"},
    {text: "Une grenouille", question: "Où est la grenouille?", hex: 0x1F438, fileName: "frog"},
    {text: "Un panda", question: "Où est le panda?", hex: 0x1F43C, fileName: "panda"},
    {text: "Un ballon de football", question: "Où est le ballon de football?", hex: 0x26BD, fileName: "football"},
    {text: "Une fontaine", question: "Où est la fontaine?", hex: 0x26F2, fileName: "fountain"},
    {text: "Un arc-en-ciel", question: "Où est l'arc-en-ciel?", hex: 0x1F308, fileName: "rainbow"},
    {text: "Un arbre", question: "Où est l'arbre?", hex: 0x1F332, fileName: "tree"},
    {text: "Une fleur", question: "Où est la fleur?", hex: 0x1F338, fileName: "flower"},
    {text: "Une tomate", question: "Où est la tomate?", hex: 0x1F345, fileName: "tomato"},
    {text: "Une aubergine", question: "Où est l'aubergine?", hex: 0x1F346, fileName: "aubergine"},
    {text: "Du raisin", question: "Où est le raisin?", hex: 0x1F347, fileName: "grapes"},
    {text: "Une pastèque", question: "Où est la pastèque?", hex: 0x1F349, fileName: "watermelon"},
    {text: "Une orange", question: "Où est l'orange?", hex: 0x1F34A, fileName: "orange"},
    {text: "Un citron", question: "Où est le citron?", hex: 0x1F34B, fileName: "lemon"},
    {text: "Une banane", question: "Où est la banane?", hex: 0x1F34C, fileName: "banana"},
    {text: "Une pomme", question: "Où est la pomme?", hex: 0x1F34F, fileName: "apple"},
    {text: "Une poire", question: "Où est la poire?", hex: 0x1F350, fileName: "pear"},
    {text: "Des cerises", question: "Où sont les cerises?", hex: 0x1F352, fileName: "cherries"},
    {text: "Une fraise", question: "Où est la fraise?", hex: 0x1F353, fileName: "strawberry"},
    {text: "Une pizza", question: "Où est la pizza?", hex: 0x1F355, fileName: "pizza"},
    {text: "Une glace", question: "Où est la glace?", hex: 0x1F366, fileName: "icecream"},
    {text: "Un cadeau", question: "Où est le cadeau?", hex: 0x1F381, fileName: "present"},
    {text: "Un gâteau", question: "Où est le gâteau?", hex: 0x1F382, fileName: "cake"},
    {text: "Une citrouille", question: "Où est la citrouille?", hex: 0x1F383, fileName: "pumpkin"},
    {text: "Un ballon", question: "Où est le ballon?", hex: 0x1F388, fileName: "balloon"},
    {text: "Un cirque", question: "Où est le cirque?", hex: 0x1F3AA, fileName: "circus"},
    {text: "Un piano", question: "Où est le piano?", hex: 0x1F3B9, fileName: "piano"},
    {text: "Un violon", question: "Où est le violon?", hex: 0x1F3BB, fileName: "violin"},
    {text: "Une plage", question: "Où est la plage?", hex: 0x1F3D6, fileName: "beach"},
    {text: "Une maison", question: "Où est la maison?", hex: 0x1F3E0, fileName: "house"},
    {text: "Un serpent", question: "Où est le serpent?", hex: 0x1F40D, fileName: "snake"},
    {text: "Un mouton", question: "Où est le mouton?", hex: 0x1F411, fileName: "sheep"},
    {text: "Une poule", question: "Où est la poule?", hex: 0x1F413, fileName: "hen"},
    {text: "Une pieuvre", question: "Où est la pieuvre?", hex: 0x1F419, fileName: "octopus"},
    {text: "Une fourmi", question: "Où est la fourmi?", hex: 0x1F41C, fileName: "ant"},
    {text: "Une abeille", question: "Où est l'abeille?", hex: 0x1F41D, fileName: "bee"},
    {text: "Une tortue", question: "Où est la tortue?", hex: 0x1F422, fileName: "turtle"},
    {text: "Un poussin", question: "Où est le poussin?", hex: 0x1F425, fileName: "chick"},
    {text: "Un chameau", question: "Où est le chameau?", hex: 0x1F42A, fileName: "camel"},
    {text: "Un dauphin", question: "Où est le dauphin?", hex: 0x1F42C, fileName: "dolphin"},
    {text: "Un tigre", question: "Où est le tigre?", hex: 0x1F42F, fileName: "tiger"},
    {text: "Un ours", question: "Où est l'ours?", hex: 0x1F43B, fileName: "bear"},
    {text: "Un écureuil", question: "Où est l'écureuil?", hex: 0x1F43F, fileName: "squirrel"},
    {text: "Un oeil", question: "Où est l'oeil?", hex: 0x1F441, fileName: "eye"},
    {text: "Une oreille", question: "Où est l'oreille?", hex: 0x1F442, fileName: "ear"},
    {text: "Un nez", question: "Où est le nez?", hex: 0x1F443, fileName: "nose"},
    {text: "Une bouche", question: "Où est la bouche?", hex: 0x1F444, fileName: "mouth"},
    {text: "Une main", question: "Où est la main?", hex: 0x1F44B, fileName: "hand"},
    {text: "Des lunettes", question: "Où sont les lunettes?", hex: 0x1F453, fileName: "glasses"},
    {text: "Un t-shirt", question: "Où est le t-shirt?", hex: 0x1F455, fileName: "tshirt"},
    {text: "Un pantalon", question: "Où est le pantalon?", hex: 0x1F456, fileName: "trousers"},
    {text: "Une robe", question: "Où est la robe?", hex: 0x1F457, fileName: "dress"},
    {text: "Une chaussure", question: "Où est la chaussure?", hex: 0x1F45E, fileName: "shoe"},
    {text: "Un garçon", question: "Où est le garçon?", hex: 0x1F466, fileName: "boy"},
    {text: "Une fille", question: "Où est la fille?", hex: 0x1F467, fileName: "girl"},
    {text: "Un livre", question: "Où est le livre?", hex: 0x1F4D7, fileName: "book"},
    {text: "Un téléphone", question: "Où est le téléphone?", hex: 0x1F4F1, fileName: "phone"},
    {text: "Le feu", question: "Où est le feu?", hex: 0x1F525, fileName: "fire"},
    {text: "Un couteau", question: "Où est le couteau?", hex: 0x1F52A, fileName: "knife"},
    {text: "Une araignée", question: "Où est l'araignée?", hex: 0x1F577, fileName: "spider"},
    {text: "Une télévision", question: "Où est la télévision?", hex: 0x1F5A5, fileName: "tv"},
    {text: "Un hélicoptère", question: "Où est l'hélicoptère?", hex: 0x1F681, fileName: "helicopter"},
    {text: "Un train", question: "Où est le train?", hex: 0x1F686, fileName: "train"},
    {text: "Un bus", question: "Où est le bus?", hex: 0x1F68C, fileName: "bus"},
    {text: "Une ambulance", question: "Où est l'ambulance?", hex: 0x1F691, fileName: "ambulance"},
    {text: "Un taxi", question: "Où est le taxi?", hex: 0x1F695, fileName: "taxi"},
    {text: "Une voiture", question: "Où est la voiture?", hex: 0x1F697, fileName: "car"},
    {text: "Un camion", question: "Où est le camion?", hex: 0x1F69B, fileName: "truck"},
    {text: "Un tracteur", question: "Où est le tracteur?", hex: 0x1F69C, fileName: "tractor"},
    {text: "Une salade", question: "Où est la salade?", hex: 0x1F957, fileName: "salad"},
    {text: "Un oeuf", question: "Où est l'oeuf?", hex: 0x1F95A, fileName: "egg"},
    {text: "Un kiwi", question: "Où est le kiwi?", hex: 0x1F95D, fileName: "kiwi"},
    {text: "Un brocoli", question: "Où est le brocoli?", hex: 0x1F966, fileName: "broccoli"},
    {text: "Un crabe", question: "Où est le crabe?", hex: 0x1F980, fileName: "crab"},
    {text: "Un lion", question: "Où est le lion?", hex: 0x1F981, fileName: "lion"},
    {text: "Un canard", question: "Où est le canard?", hex: 0x1F986, fileName: "duck"},
    {text: "Une chauve-souris", question: "Où est la chauve-souris?", hex: 0x1F987, fileName: "bat"},
    {text: "Un requin", question: "Où est le requin?", hex: 0x1F988, fileName: "shark"},
    {text: "Un bateau", question: "Où est le bateau?", hex: 0x1F6A2, fileName: "boat"},
    {text: "Un vélo", question: "Où est le vélo?", hex: 0x1F6B2, fileName: "bike"},
    {text: "Un lit", question: "Où est le lit?", hex: 0x1F6CC, fileName: "bed"},
    {text: "Un avion", question: "Où est l'avion?", hex: 0x1F6E9, fileName: "plane"},
    {text: "Une moto", question: "Où est la moto?", hex: 0x1F6F5, fileName: "motorbike"},
    {text: "Un tambour", question: "Où est le tambour ?", hex: 0x1F941, fileName: "drums"},
    {text: "Un avocat", question: "Où est l'avocat ?", hex: 0x1F951, fileName: "avocado"},
    {text: "Un concombre", question: "Où est le concombre ?", hex: 0x1F952, fileName: "cucumber"},
    {text: "Une carotte", question: "Où est la carotte?", hex: 0x1F955, fileName: "carrot"},
    {text: "Un coeur", question: "Où est le coeur?", hex: 0x1F9E1, fileName: "heart"},
    {text: "Un hibou", question: "Où est le hibou?", hex: 0x1F989, fileName: "owl"},
    {text: "Un papillon", question: "Où est le papillon?", hex: 0x1F98B, fileName: "butterfly"},
    {text: "Un gorille", question: "Où est le gorille?", hex: 0x1F98D, fileName: "gorilla"},
    {text: "Une girafe", question: "Où est la girafe?", hex: 0x1F992, fileName: "giraffe"},
    {text: "Un zèbre", question: "Où est le zèbre?", hex: 0x1F993, fileName: "zebra"},
    {text: "Un dinosaure", question: "Où est le dinosaure?", hex: 0x1F996, fileName: "dinosaur"},
    {text: "Le soleil", question: "Où est le soleil?", hex: 0x1F31E, fileName: "sun"},
    {text: "La lune", question: "Où est la lune?", hex: 0x1F31B, fileName: "moon"},
    {text: "Une étoile", question: "Où est l'étoile?", hex: 0x1F31F, fileName: "star"},
    {text: "La pluie", question: "Où est la pluie?", hex: 0x1F327, fileName: "rain"},
    {text: "Une voiture de police", question: "Où est la voiture de police?", hex: 0x1F694, fileName: "policecar"},
    {text: "Un camion de pompiers", question: "Où est le camion de pompiers?", hex: 0x1F692, fileName: "fireengine"},
    {text: "Un tramway", question: "Où est le tramway?", hex: 0x1F683, fileName: "tram"},
    {text: "Une fusée", question: "Où est la fusée?", hex: 0x1F680, fileName: "rocket"},
    {text: "Un bonhomme de neige", question: "Où est le bonhomme de neige?", hex: 0x26C4, fileName: "snowman"},
    {text: "Une montagne", question: "Où est la montagne?", hex: 0x1f3d4, fileName: "mountain"},
    {text: "Une vague", question: "Où est la vague?", hex: 0x1F30A, fileName: "wave"},
    {text: "Un ananas", question: "Où est l'ananas?", hex: 0x1F34D, fileName: "pineapple"},
    {text: "Un biscuit", question: "Où est le biscuit?", hex: 0x1F36A, fileName: "cookie"},
    {text: "Un château", question: "Où est le château?", hex: 0x1F3F0, fileName: "castle"},
    {text: "Un cerf", question: "Où est le cerf?", hex: 0x1F98C, fileName: "deer"},
    {text: "Un ordinateur portable", question: "Où est l'ordinateur portable?", hex: 0x1F4BB, fileName: "laptop"},
    {text: "Une trompette", question: "Où est la trompette?", hex: 0x1F3BA, fileName: "trumpet"},
    {text: "Une coccinelle", question: "Où est la coccinelle?", hex: 0x1F41E, fileName: "ladybug"},
    {text: "Du maïs", question: "Où est le maïs?", hex: 0x1F33D, fileName: "corn"},
    {text: "Un café", question: "Où est le café?", hex: 0x2615, fileName: "coffee"},
    {text: "Une tente", question: "Où est la tente?", hex: 0x26FA, fileName: "tent"},
    {text: "Une agent de police", question: "Où est l'agent de police?", hex: 0x1F46E, fileName: "police"},
    {text: "Un homme", question: "Où est l'homme?", hex: 0x1F468, fileName: "man"},
    {text: "Une femme", question: "Où est la femme?", hex: 0x1F469, fileName: "woman"},
    {text: "Un bébé", question: "Où est le bébé?", hex: 0x1F476, fileName: "baby"},
    {text: "Un robot", question: "Où est le robot?", hex: 0x1F916, fileName: "robot"},
    {text: "Un clown", question: "Où est le clown?", hex: 0x1F921, fileName: "clown"},
    {text: "Une langue", question: "Où est la langue?", hex: 0x1F445, fileName: "tongue"},
    {text: "Un bras", question: "Où est le bras?", hex: 0x1F4AA, fileName: "arm"},
    {text: "Un os", question: "Où est l'os?", hex: 0x1F9B4, fileName: "bone"},
    {text: "Une jambe", question: "Où est la jambe?", hex: 0x1F9B5, fileName: "leg"},
    {text: "Un pied", question: "Où est le pied?", hex: 0x1F9B6, fileName: "foot"},
    {text: "Une dent", question: "Où est la dent?", hex: 0x1F9B7, fileName: "tooth"},
    {text: "Un fauteuil roulant", question: "Où est le fauteuil roulant?", hex: 0x1F9BD, fileName: "wheelchair"},
    {text: "Une bague", question: "Où est la bague?", hex: 0x1F48D, fileName: "ring"},
    {text: "Un stylo", question: "Où est le stylo?", hex: 0x1F58A, fileName: "pen"},
    {text: "Un paquet", question: "Où est le paquet?", hex: 0x1F4E6, fileName: "package"},
    {text: "Une horloge", question: "Où est l'horloge?", hex: 0x1F570, fileName: "clock"},
    {text: "Une princesse", question: "Où est la princesse?", hex: 0x1F478, fileName: "princess"},
    {text: "Un tableau", question: "Où est le tableau?", hex: 0x1F5BC, fileName: "painting"},
    {text: "Une corbeille à papier", question: "Où est la corbeille à papier?", hex: 0x1F5D1, fileName: "bin"},
    {text: "Un volcan", question: "Où est le volcan?", hex: 0x1F30B, fileName: "volcano"},
    {text: "Un hôtel", question: "Où est l'hôtel?", hex: 0x1F3E8, fileName: "hotel"},
    {text: "Un hôpital", question: "Où est l'hôpital?", hex: 0x1F3E5, fileName: "hospital"},
    {text: "Un stade", question: "Où est le stade?", hex: 0x1F3DF, fileName: "stadium"},
    {text: "Une toilette", question: "Où est la toilette?", hex: 0x1F6BD, fileName: "toilet"},
    {text: "Une baignoire", question: "Où est la baignoire?", hex: 0x1F6C1, fileName: "bathtub"},
    {text: "Des patins à roulettes", question: "Où sont les patins à roulettes?", hex: 0x1F6FC, fileName: "rollerskates"},
    {text: "Une trottinette", question: "Où est la trottinette?", hex: 0x1F6F4, fileName: "scooter"},
    {text: "Une chèvre", question: "Où est la chèvre?", hex: 0x1F410, fileName: "goat"},
    {text: "Un sanglier", question: "Où est le sanglier?", hex: 0x1F417, fileName: "wildpig"},
    {text: "Un aigle", question: "Où est l'aigle?", hex: 0x1F985, fileName: "eagle"},
    {text: "Un rhinocéros", question: "Où est le rhinocéros?", hex: 0x1F98F, fileName: "rhino"},
    {text: "Un paon", question: "Où est le paon?", hex: 0x1F99A, fileName: "peacock"},
    {text: "Un hippopotame", question: "Où est l'hippopotame?", hex: 0x1F99B, fileName: "hippo"},
    {text: "Un perroquet", question: "Où est le perroquet?", hex: 0x1F99C, fileName: "parrot"},
    {text: "Un moustique", question: "Où est le moustique?", hex: 0x1F99F, fileName: "mosquito"},
    {text: "Un cygne", question: "Où est le cygne?", hex: 0x1F9A2, fileName: "swan"},
    {text: "Un phoque", question: "Où est le phoque?", hex: 0x1F9AD, fileName: "seal"},
    {text: "Un hamburger", question: "Où est le hamburger?", hex: 0x1F354, fileName: "burger"},
    {text: "Une baguette", question: "Où est la baguette?", hex: 0x1F956, fileName: "bread"},
    {text: "Des cacahuètes", question: "Où sont les cacahuètes?", hex: 0x1F95C, fileName: "peanuts"},
    {text: "Du fromage", question: "Où est le fromage?", hex: 0x1F9C0, fileName: "cheese"},
    {text: "Une gousse d'ail", question: "Où est la gousse d'ail?", hex: 0x1F9C4, fileName: "garlic"},
    {text: "Un oignon", question: "Où est l'oignon?", hex: 0x1F9C5, fileName: "onion"},
    {text: "Une cuillère", question: "Où est la cuillère?", hex: 0x1F944, fileName: "spoon"},
    {text: "Un cactus", question: "Où est le cactus?", hex: 0x1F335, fileName: "cactus"},
    {text: "Un champignon", question: "Où est le champignon?", hex: 0x1F344, fileName: "mushroom"},
    {text: "Une pêche", question: "Où est la pêche?", hex: 0x1F351, fileName: "peach"},
    {text: "Un ballon de basket", question: "Où est le ballon de basket?", hex: 0x1F3C0, fileName: "basketball"},
    {text: "Une balle de tennis", question: "Où est la balle de tennis?", hex: 0x1F3BE, fileName: "tennis"},
    {text: "Une médaille", question: "Où est la médaille?", hex: 0x1F3C5, fileName: "medal"},
    {text: "Un parapluie", question: "Où est le parapluie?", hex: 0x2614, fileName: "umbrella"},
    {text: "Un bikini", question: "Où est le bikini?", hex: 0x1F459, fileName: "bikini"},
    {text: "Des chaussettes", question: "Où sont les chaussettes?", hex: 0x1F9E6, fileName: "socks"},
    {text: "Une casquette", question: "Où est la casquette?", hex: 0x1F9E2, fileName: "cap"},
    {text: "Des gants", question: "Où sont les gants?", hex: 0x1F9E4, fileName: "gloves"},
    {text: "Une écharpe", question: "Où est l'écharpe?", hex: 0x1F9E3, fileName: "scarf"},
    {text: "Un sac à main", question: "Où est le sac à main?", hex: 0x1F45C, fileName: "handbag"},
    {text: "Un saxophone", question: "Où est le saxophone?", hex: 0x1F3B7, fileName: "saxophone"},
    {text: "Une clé", question: "Où est la clé?", hex: 0x1F511, fileName: "key"},
    {text: "Un marteau", question: "Où est le marteau?", hex: 0x1F528, fileName: "hammer"},
    {text: "Une cloche", question: "Où est la cloche?", hex: 0x1F514, fileName: "bell"},
    {text: "Un balai", question: "Où est le balai?", hex: 0x1F9F9, fileName: "broom"},
    {text: "Une bougie", question: "Où est la bougie?", hex: 0x1F56F, fileName: "candle"},
    {text: "Une ampoule", question: "Où est l'ampoule?", hex: 0x1F4A1, fileName: "lightbulb"},
    {text: "Una porte", question: "Où est la porte?", hex: 0x1F6AA, fileName: "door"}
];

// eslint-disable-next-line no-unused-vars
const KIDS_MAP_NO = [
    {text: "En mus", question: "Hvor er musen?", hex: 0x1F401, fileName: "mouse"},
    {text: "Ei ku", question: "Hvor er kua?", hex: 0x1F404, fileName: "cow"},
    {text: "En kanin", question: "Hvor er kaninen?", hex: 0x1F407, fileName: "rabbit"},
    {text: "En katt", question: "Hvor er katten?", hex: 0x1F408, fileName: "cat"},
    {text: "En krokodille", question: "Hvor er krokodillen?", hex: 0x1F40A, fileName: "crocodile"},
    {text: "En hval", question: "Hvor er hvalen?", hex: 0X1F40B, fileName: "whale"},
    {text: "En snegl", question: "Hvor er sneglen?", hex: 0x1F40C, fileName: "snail"},
    {text: "En elefant", question: "Hvor er elefanten?", hex: 0x1F418, fileName: "elephant"},
    {text: "En fisk", question: "Hvor er fisken?", hex: 0x1F41F, fileName: "fish"},
    {text: "En koala", question: "Hvor er koalaen?", hex: 0x1F428, fileName: "koala"},
    {text: "En hest", question: "Hvor er hesten?", hex: 0x1F434, fileName: "horse"},
    {text: "En ape", question: "Hvor er apen?", hex: 0x1F435, fileName: "monkey"},    
    {text: "En hund", question: "Hvor er hunden?", hex: 0x1F436, fileName: "dog"},
    {text: "En gris", question: "Hvor er grisen?", hex: 0x1F437, fileName: "pig"}, 
    {text: "En frosk", question: "Hvor er frosken?", hex: 0x1F438, fileName: "frog"},
    {text: "En panda", question: "Hvor er pandaen?", hex: 0x1F43C, fileName: "panda"},
    {text: "En fotball", question: "Hvor er fotballen?", hex: 0x26BD, fileName: "football"},
    {text: "En fontene", question: "Hvor er fontenen?", hex: 0x26F2, fileName: "fountain"},
    {text: "En regnbue", question: "Hvor er regnbuen?", hex: 0x1F308, fileName: "rainbow"},
    {text: "Et tre", question: "Hvor er treet?", hex: 0x1F332, fileName: "tree"},
    {text: "En blomst", question: "Hvor er blomsten?", hex: 0x1F338, fileName: "flower"},
    {text: "En tomat", question: "Hvor er tomaten?", hex: 0x1F345, fileName: "tomato"},
    {text: "En aubergine", question: "Hvor er auberginen?", hex: 0x1F346, fileName: "aubergine"},
    {text: "Druer", question: "Hvor er druene?", hex: 0x1F347, fileName: "grapes"},
    {text: "En vannmelon", question: "Hvor er vannmelonen?", hex: 0x1F349, fileName: "watermelon"},
    {text: "En appelsin", question: "Hvor er appelsinen?", hex: 0x1F34A, fileName: "orange"},
    {text: "En sitron", question: "Hvor er sitronen?", hex: 0x1F34B, fileName: "lemon"},
    {text: "En banan", question: "Hvor er bananen?", hex: 0x1F34C, fileName: "banana"},
    {text: "Et eple", question: "Hvor er eplet?", hex: 0x1F34F, fileName: "apple"},
    {text: "En pære", question: "Hvor er pæren?", hex: 0x1F350, fileName: "pear"},
    {text: "Kirsebær", question: "Hvor er kirsebærene?", hex: 0x1F352, fileName: "cherries"},
    {text: "Jordbær", question: "Hvor er jordbærene?", hex: 0x1F353, fileName: "strawberry"},
    {text: "En pizza", question: "Hvor er pizzaen?", hex: 0x1F355, fileName: "pizza"},
    {text: "En iskrem", question: "Hvor er iskremen?", hex: 0x1F366, fileName: "icecream"},
    {text: "En gave", question: "Hvor er gaven?", hex: 0x1F381, fileName: "present"},
    {text: "En kake", question: "Hvor er kaken?", hex: 0x1F382, fileName: "cake"},
    {text: "Et gresskar", question: "Hvor er gresskaret?", hex: 0x1F383, fileName: "pumpkin"},
    {text: "En ballong", question: "Hvor er ballongen?", hex: 0x1F388, fileName: "balloon"},
    {text: "Et sirkus", question: "Hvor er sirkuset?", hex: 0x1F3AA, fileName: "circus"},
    {text: "Et piano", question: "Hvor er pianoet?", hex: 0x1F3B9, fileName: "piano"},
    {text: "En fiolin", question: "Hvor er fiolinen?", hex: 0x1F3BB, fileName: "violin"},
    {text: "En strand", question: "Hvor er stranden?", hex: 0x1F3D6, fileName: "beach"},
    {text: "Et hus", question: "Hvor er huset?", hex: 0x1F3E0, fileName: "house"},
    {text: "En slange", question: "Hvor er slangen?", hex: 0x1F40D, fileName: "snake"},
    {text: "En sau", question: "Hvor er sauen?", hex: 0x1F411, fileName: "sheep"},
    {text: "En høne", question: "Hvor er hønen?", hex: 0x1F413, fileName: "hen"},
    {text: "En blekksprut", question: "Hvor er blekkspruten?", hex: 0x1F419, fileName: "octopus"},
    {text: "En maur", question: "Hvor er mauren?", hex: 0x1F41C, fileName: "ant"},
    {text: "En bie", question: "Hvor er bien?", hex: 0x1F41D, fileName: "bee"},
    {text: "En skilpadde", question: "Hvor er skilpadden?", hex: 0x1F422, fileName: "turtle"},
    {text: "En kylling", question: "Hvor er kyllingen?", hex: 0x1F425, fileName: "chick"},
    {text: "En kamel", question: "Hvor er kamelen?", hex: 0x1F42A, fileName: "camel"},
    {text: "En delfin", question: "Hvor er delfinen?", hex: 0x1F42C, fileName: "dolphin"},
    {text: "En tiger", question: "Hvor er tigeren?", hex: 0x1F42F, fileName: "tiger"},
    {text: "En bjørn", question: "Hvor er bjørnen?", hex: 0x1F43B, fileName: "bear"},
    {text: "Et ekorn", question: "Hvor er ekornet?", hex: 0x1F43F, fileName: "squirrel"},
    {text: "Et øye", question: "Hvor er øyet?", hex: 0x1F441, fileName: "eye"},
    {text: "Et øre", question: "Hvor er øret?", hex: 0x1F442, fileName: "ear"},
    {text: "En nese", question: "Hvor er nesen?", hex: 0x1F443, fileName: "nose"},
    {text: "En munn", question: "Hvor er munnen?", hex: 0x1F444, fileName: "mouth"},
    {text: "En hånd", question: "Hvor er hånden?", hex: 0x1F44B, fileName: "hand"},
    {text: "Briller", question: "Hvor er brillene?", hex: 0x1F453, fileName: "glasses"},
    {text: "En t-skjorte", question: "Hvor er t-skjorten?", hex: 0x1F455, fileName: "tshirt"},
    {text: "En bukse", question: "Hvor er buksen?", hex: 0x1F456, fileName: "trousers"},
    {text: "En kjole", question: "Hvor er kjolen?", hex: 0x1F457, fileName: "dress"},
    {text: "En sko", question: "Hvor er skoen?", hex: 0x1F45E, fileName: "shoe"},
    {text: "En gutt", question: "Hvor er gutten?", hex: 0x1F466, fileName: "boy"},
    {text: "Ei jente", question: "Hvor er jenta?", hex: 0x1F467, fileName: "girl"},
    {text: "En bok", question: "Hvor er boken?", hex: 0x1F4D7, fileName: "book"},
    {text: "En telefon", question: "Hvor er telefonen?", hex: 0x1F4F1, fileName: "phone"},
    {text: "En brann", question: "Hvor er brannen?", hex: 0x1F525, fileName: "fire"},
    {text: "En kniv", question: "Hvor er kniven?", hex: 0x1F52A, fileName: "knife"},
    {text: "En edderkopp", question: "Hvor er edderkoppen?", hex: 0x1F577, fileName: "spider"},
    {text: "En TV", question: "Hvor er TVen?", hex: 0x1F5A5, fileName: "tv"},
    {text: "Et helikopter", question: "Hvor er helikopteret?", hex: 0x1F681, fileName: "helicopter"},
    {text: "Et tog", question: "Hvor er toget?", hex: 0x1F686, fileName: "train"},
    {text: "En buss", question: "Hvor er bussen?", hex: 0x1F68C, fileName: "bus"},
    {text: "En ambulanse", question: "Hvor er ambulansen?", hex: 0x1F691, fileName: "ambulance"},
    {text: "En taxi", question: "Hvor er taxien?", hex: 0x1F695, fileName: "taxi"},
    {text: "En bil", question: "Hvor er bilen?", hex: 0x1F697, fileName: "car"},
    {text: "En lastebil", question: "Hvor er lastebilen?", hex: 0x1F69B, fileName: "truck"},
    {text: "En traktor", question: "Hvor er traktoren?", hex: 0x1F69C, fileName: "tractor"},
    {text: "En salat", question: "Hvor er salaten?", hex: 0x1F957, fileName: "salad"},
    {text: "Et egg", question: "Hvor er egget?", hex: 0x1F95A, fileName: "egg"},
    {text: "En kiwi", question: "Hvor er kiwien?", hex: 0x1F95D, fileName: "kiwi"},
    {text: "En brokkoli", question: "Hvor er brokkolien?", hex: 0x1F966, fileName: "broccoli"},
    {text: "En krabbe", question: "Hvor er krabben?", hex: 0x1F980, fileName: "crab"},
    {text: "En løve", question: "Hvor er løven?", hex: 0x1F981, fileName: "lion"},
    {text: "En and", question: "Hvor er anden?", hex: 0x1F986, fileName: "duck"},
    {text: "En flaggermus", question: "Hvor er flaggermusen?", hex: 0x1F987, fileName: "bat"},
    {text: "En hai", question: "Hvor er haien?", hex: 0x1F988, fileName: "shark"},
    {text: "En båt", question: "Hvor er båten?", hex: 0x1F6A2, fileName: "boat"},
    {text: "En sykkel", question: "Hvor er sykkelen?", hex: 0x1F6B2, fileName: "bike"},
    {text: "En seng", question: "Hvor er sengen?", hex: 0x1F6CC, fileName: "bed"},
    {text: "Et fly", question: "Hvor er flyet?", hex: 0x1F6E9, fileName: "plane"},
    {text: "En motorsykkel", question: "Hvor er motorsykkelen?", hex: 0x1F6F5, fileName: "motorbike"},
    {text: "Trommer", question: "Hvor er trommene?", hex: 0x1F941, fileName: "drums"},
    {text: "En avokado", question: "Hvor er avokadoen?", hex: 0x1F951, fileName: "avocado"},
    {text: "En agurk", question: "Hvor er agurken?", hex: 0x1F952, fileName: "cucumber"},
    {text: "En gulrot", question: "Hvor er gulroten?", hex: 0x1F955, fileName: "carrot"},
    {text: "Et hjerte", question: "Hvor er hjertet?", hex: 0x1F9E1, fileName: "heart"},
    {text: "En ugle", question: "Hvor er uglen?", hex: 0x1F989, fileName: "owl"},
    {text: "En sommerfugl", question: "Hvor er sommerfuglen?", hex: 0x1F98B, fileName: "butterfly"},
    {text: "En gorilla", question: "Hvor er gorillaen?", hex: 0x1F98D, fileName: "gorilla"},
    {text: "En sjiraff", question: "Hvor er sjiraffen?", hex: 0x1F992, fileName: "giraffe"},
    {text: "En sebra", question: "Hvor er sebraen?", hex: 0x1F993, fileName: "zebra"},
    {text: "En dinosaur", question: "Hvor er dinosauren?", hex: 0x1F996, fileName: "dinosaur"},
    {text: "En sol", question: "Hvor er solen?", hex: 0x1F31E, fileName: "sun"},
    {text: "En måne", question: "Hvor er månen?", hex: 0x1F31B, fileName: "moon"},
    {text: "En stjerne", question: "Hvor er stjernen?", hex: 0x1F31F, fileName: "star"},
    {text: "Regn", question: "Hvor er regnet?", hex: 0x1F327, fileName: "rain"},
    {text: "En politibil", question: "Hvor er politibilen?", hex: 0x1F694, fileName: "policecar"},
    {text: "En brannbil", question: "Hvor er brannbilen?", hex: 0x1F692, fileName: "fireengine"},
    {text: "En trikk", question: "Hvor er trikken?", hex: 0x1F683, fileName: "tram"},
    {text: "En rakett", question: "Hvor er raketten?", hex: 0x1F680, fileName: "rocket"},
    {text: "En snømann", question: "Hvor er snømannen?", hex: 0x26C4, fileName: "snowman"},
    {text: "Et fjell", question: "Hvor er fjellet?", hex: 0x1f3d4, fileName: "mountain"},
    {text: "En bølge", question: "Hvor er bølgen?", hex: 0x1F30A, fileName: "wave"},
    {text: "En ananas", question: "Hvor er ananasen?", hex: 0x1F34D, fileName: "pineapple"},
    {text: "En kjeks", question: "Hvor er kjeksen?", hex: 0x1F36A, fileName: "cookie"},
    {text: "Et slott", question: "Hvor er slottet?", hex: 0x1F3F0, fileName: "castle"},
    {text: "En hjort", question: "Hvor er hjorten?", hex: 0x1F98C, fileName: "deer"},
    {text: "En datamaskin", question: "Hvor er datamaskinen?", hex: 0x1F4BB, fileName: "laptop"},
    {text: "En trompet", question: "Hvor er trompeten?", hex: 0x1F3BA, fileName: "trumpet"},
    {text: "En marihøne", question: "Hvor er marihøna?", hex: 0x1F41E, fileName: "ladybug"},
    {text: "Mais", question: "Hvor er maisen?", hex: 0x1F33D, fileName: "corn"},
    {text: "En kaffe", question: "Hvor er kaffen?", hex: 0x2615, fileName: "coffee"},
    {text: "Et telt", question: "Hvor er teltet?", hex: 0x26FA, fileName: "tent"},
    {text: "En politikvinne", question: "Hvor er politikvinnen?", hex: 0x1F46E, fileName: "police"},
    {text: "En mann", question: "Hvor er mannen?", hex: 0x1F468, fileName: "man"},
    {text: "En kvinne", question: "Hvor er kvinnen?", hex: 0x1F469, fileName: "woman"},
    {text: "En baby", question: "Hvor er babyen?", hex: 0x1F476, fileName: "baby"},
    {text: "En robot", question: "Hvor er roboten?", hex: 0x1F916, fileName: "robot"},
    {text: "En klovn", question: "Hvor er klovnen?", hex: 0x1F921, fileName: "clown"},
    {text: "En tunge", question: "Hvor er tungen?", hex: 0x1F445, fileName: "tongue"},
    {text: "En arm", question: "Hvor er armen?", hex: 0x1F4AA, fileName: "arm"},
    {text: "Et bein", question: "Hvor er beinet?", hex: 0x1F9B4, fileName: "bone"},
    {text: "Et ben", question: "Hvor er benet?", hex: 0x1F9B5, fileName: "leg"},
    {text: "En fot", question: "Hvor er foten?", hex: 0x1F9B6, fileName: "foot"},
    {text: "En tann", question: "Hvor er tannen?", hex: 0x1F9B7, fileName: "tooth"},
    {text: "En rullestol", question: "Hvor er rullestolen?", hex: 0x1F9BD, fileName: "wheelchair"},
    {text: "En ring", question: "Hvor er ringen?", hex: 0x1F48D, fileName: "ring"},
    {text: "En penn", question: "Hvor er pennen?", hex: 0x1F58A, fileName: "pen"},
    {text: "En pakke", question: "Hvor er pakken?", hex: 0x1F4E6, fileName: "package"},
    {text: "En klokke", question: "Hvor er klokken?", hex: 0x1F570, fileName: "clock"},
    {text: "En prinsesse", question: "Hvor er prinsessen?", hex: 0x1F478, fileName: "princess"},
    {text: "Et maleri", question: "Hvor er maleriet?", hex: 0x1F5BC, fileName: "painting"},
    {text: "En søppelbøtte", question: "Hvor er søppelbøtten?", hex: 0x1F5D1, fileName: "bin"},
    {text: "En vulkan", question: "Hvor er vulkanen?", hex: 0x1F30B, fileName: "volcano"},
    {text: "Et hotell", question: "Hvor er hotellet?", hex: 0x1F3E8, fileName: "hotel"},
    {text: "Et sykehus", question: "Hvor er sykehuset?", hex: 0x1F3E5, fileName: "hospital"},
    {text: "Et stadion", question: "Hvor er stadion?", hex: 0x1F3DF, fileName: "stadium"},
    {text: "En toalett", question: "Hvor er toalettet?", hex: 0x1F6BD, fileName: "toilet"},
    {text: "Et badekar", question: "Hvor er badekaret?", hex: 0x1F6C1, fileName: "bathtub"},
    {text: "Rulleskøyter", question: "Hvor er rulleskøytene?", hex: 0x1F6FC, fileName: "rollerskates"},
    {text: "En sparkesykkel", question: "Hvor er sparkesykkelen?", hex: 0x1F6F4, fileName: "scooter"},
    {text: "En geit", question: "Hvor er geiten?", hex: 0x1F410, fileName: "goat"},
    {text: "En villsvin", question: "Hvor er villsvinet?", hex: 0x1F417, fileName: "wildpig"},
    {text: "En ørn", question: "Hvor er ørnen?", hex: 0x1F985, fileName: "eagle"},
    {text: "En neshorn", question: "Hvor er neshornet?", hex: 0x1F98F, fileName: "rhino"},
    {text: "En påfugl", question: "Hvor er påfuglen?", hex: 0x1F99A, fileName: "peacock"},
    {text: "En flodhest", question: "Hvor er flodhesten?", hex: 0x1F99B, fileName: "hippo"},
    {text: "En papegøye", question: "Hvor er papegøyen?", hex: 0x1F99C, fileName: "parrot"},
    {text: "En mygg", question: "Hvor er myggen?", hex: 0x1F99F, fileName: "mosquito"},
    {text: "En svane", question: "Hvor er svanen?", hex: 0x1F9A2, fileName: "swan"},
    {text: "En sel", question: "Hvor er selen?", hex: 0x1F9AD, fileName: "seal"},
    {text: "En hamburger", question: "Hvor er hamburgeren?", hex: 0x1F354, fileName: "burger"},
    {text: "En baguette", question: "Hvor er baguetten?", hex: 0x1F956, fileName: "bread"},
    {text: "Peanøtter", question: "Hvor er peanøttene?", hex: 0x1F95C, fileName: "peanuts"},
    {text: "Ost", question: "Hvor er osten?", hex: 0x1F9C0, fileName: "cheese"},
    {text: "En hvitløk", question: "Hvor er hvitløken?", hex: 0x1F9C4, fileName: "garlic"},
    {text: "En løk", question: "Hvor er løken?", hex: 0x1F9C5, fileName: "onion"},
    {text: "En skje", question: "Hvor er skjeen?", hex: 0x1F944, fileName: "spoon"},
    {text: "En kaktus", question: "Hvor er kaktusen?", hex: 0x1F335, fileName: "cactus"},
    {text: "En sopp", question: "Hvor er soppen?", hex: 0x1F344, fileName: "mushroom"},
    {text: "En fersken", question: "Hvor er ferskenen?", hex: 0x1F351, fileName: "peach"},
    {text: "En basketball", question: "Hvor er basketballen?", hex: 0x1F3C0, fileName: "basketball"},
    {text: "En tennisball", question: "Hvor er tennisballen?", hex: 0x1F3BE, fileName: "tennis"},
    {text: "En medalje", question: "Hvor er medaljen?", hex: 0x1F3C5, fileName: "medal"},
    {text: "En paraply", question: "Hvor er paraplyen?", hex: 0x2614, fileName: "umbrella"},
    {text: "En bikini", question: "Hvor er bikinien?", hex: 0x1F459, fileName: "bikini"},
    {text: "Sokker", question: "Hvor er sokkene?", hex: 0x1F9E6, fileName: "socks"},
    {text: "En caps", question: "Hvor er capsen?", hex: 0x1F9E2, fileName: "cap"},
    {text: "Hansker", question: "Hvor er hanskene?", hex: 0x1F9E4, fileName: "gloves"},
    {text: "Et skjerf", question: "Hvor er skjerfet?", hex: 0x1F9E3, fileName: "scarf"},
    {text: "En veske", question: "Hvor er vesken?", hex: 0x1F45C, fileName: "handbag"},
    {text: "En saksofon", question: "Hvor er saksofonen?", hex: 0x1F3B7, fileName: "saxophone"},
    {text: "En nøkkel", question: "Hvor er nøkkelen?", hex: 0x1F511, fileName: "key"},
    {text: "En hammer", question: "Hvor er hammeren?", hex: 0x1F528, fileName: "hammer"},
    {text: "En bjelle", question: "Hvor er bjellen?", hex: 0x1F514, fileName: "bell"},
    {text: "En kost", question: "Hvor er kosten?", hex: 0x1F9F9, fileName: "broom"},
    {text: "Et lys", question: "Hvor er lyset?", hex: 0x1F56F, fileName: "candle"},
    {text: "En lyspære", question: "Hvor er lyspæren?", hex: 0x1f4a1, fileName: "lightbulb"},
    {text: "En dør", question: "Hvor er døren?", hex: 0x1F6AA, fileName: "door"}
];

export const KidsEmoji = (props) => (
    <span role="img" className={props.className} onTouchStart={props.touchHandler} onMouseDown={props.clickHandler}>
        <span>{String.fromCodePoint(eval("KIDS_MAP_"+ props.lang)[props.num].hex)}</span>
    </span>
);

export const KidsLabel = (props) => (
    <span className={props.className}>
        {eval("KIDS_MAP_"+ props.lang)[props.num].text}
    </span>
);

export const KidsQuestion = (props) => (
    <span className={props.className}>
        {eval("KIDS_MAP_"+ props.lang)[props.num].question}
    </span>
);

export function playAudioByItemNumber(itemNumber, lang, audioObject, isQuestion) {
    audioObject.src = audio('./' + lang + "/" + eval("KIDS_MAP_"+ lang)[itemNumber].fileName 
        + (isQuestion ? '_q' : '') + '.mp3');
    audioObject.play().catch(() => {
          // Auto-play was prevented
        });
}