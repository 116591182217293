import React from 'react';

const images = require.context("../../images/flags", true);
const FLAG_MAP = [
    {name: "Afghanistan", code: "af"},
    {name: "Albania", code: "al"},
    {name: "Algeria", code: "dz"},
    {name: "Angola", code: "ao"},
    {name: "Antigua and Barbuda", code: "ag"},
    {name: "Argentina", code: "ar"},
    {name: "Armenia", code: "am"},
    {name: "Australia", code: "au"},
    {name: "Austria", code: "at"},
    {name: "Barbados", code: "bb"},
    {name: "Belarus", code: "by"},
    {name: "Belgium", code: "be"},
    {name: "Benin", code: "bj"},
    {name: "Bhutan", code: "bt"},
    {name: "Bolivia", code: "bo"},
    {name: "Bosnia Herzegovina", code: "ba"},
    {name: "Botswana", code: "bw"},
    {name: "Brazil", code: "br"},
    {name: "Bulgaria", code: "bg"},
    {name: "Burkina Faso", code: "bf"},
    {name: "Cameroon", code: "cm"},
    {name: "Canada", code: "ca"},
    {name: "Central African Rep.", code: "cf"},
    {name: "Chile", code: "cl"},
    {name: "China", code: "cn"},
    {name: "Colombia", code: "co"},
    {name: "Congo", code: "cg"},
    {name: "Costa Rica", code: "cr"},
    {name: "Côte d'Ivoire", code: "ci"},
    {name: "Croatia", code: "hr"},
    {name: "Cuba", code: "cu"},
    {name: "Cyprus", code: "cy"},
    {name: "Czech Republic", code: "cz"},
    {name: "Denmark", code: "dk"},
    {name: "Djibouti", code: "dj"},
    {name: "Dominican Republic", code: "do"},
    {name: "Egypt", code: "eg"},
    {name: "Equatorial Guinea", code: "gq"},
    {name: "Estonia", code: "ee"},
    {name: "Eswatini", code: "sz"},
    {name: "Ethiopia", code: "et"},
    {name: "Finland", code: "fi"},
    {name: "France", code: "fr"},
    {name: "Gambia", code: "gm"},
    {name: "Georgia", code: "ge"},
    {name: "Germany", code: "de"},
    {name: "Ghana", code: "gh"},
    {name: "Greece", code: "gr"},
    {name: "Guatemala", code: "gt"},
    {name: "Honduras", code: "hn"},
    {name: "Hungary", code: "hu"},
    {name: "Iceland", code: "is"},
    {name: "India", code: "in"},
    {name: "Indonesia", code: "id"},
    {name: "Iran", code: "ir"},
    {name: "Iraq", code: "iq"},
    {name: "Ireland", code: "ie"},
    {name: "Israel", code: "il"},
    {name: "Italy", code: "it"},
    {name: "Jamaica", code: "jm"},
    {name: "Japan", code: "jp"},
    {name: "Kazakhstan", code: "kz"},
    {name: "Kenya", code: "ke"},
    {name: "Laos", code: "la"},
    {name: "Latvia", code: "lv"},
    {name: "Lebanon", code: "lb"},
    {name: "Lesotho", code: "ls"},
    {name: "Lithuania", code: "lt"},
    {name: "Madagascar", code: "mg"},
    {name: "Malawi", code: "mw"},
    {name: "Malaysia", code: "my"},
    {name: "Maldives", code: "mv"},
    {name: "Mali", code: "ml"},
    {name: "Malta", code: "mt"},
    {name: "Mauritania", code: "mr"},
    {name: "Mauritius", code: "mu"},
    {name: "Mexico", code: "mx"},
    {name: "Mongolia", code: "mn"},
    {name: "Morocco", code: "ma"},
    {name: "Mozambique", code: "mz"},
    {name: "Myanmar", code: "mm"},
    {name: "Namibia", code: "na"},
    {name: "Nepal", code: "np"},
    {name: "Netherlands", code: "nl"},
    {name: "Nigeria", code: "ng"},
    {name: "North Korea", code: "kp"},
    {name: "North Macedonia", code: "mk"},
    {name: "Norway", code: "no"},
    {name: "Pakistan", code: "pk"},
    {name: "Panama", code: "pa"},
    {name: "Peru", code: "pe"},
    {name: "Philippines", code: "ph"},
    {name: "Poland", code: "pl"},
    {name: "Portugal", code: "pt"},
    {name: "Qatar", code: "qa"},
    {name: "Romania", code: "ro"},
    {name: "Russia", code: "ru"},
    {name: "Rwanda", code: "rw"},
    {name: "Saint Kitts & Nevis", code: "kn"},
    {name: "St. Vincent & Grenadines", code: "vc"},
    {name: "Saudi Arabia", code: "sa"},
    {name: "Senegal", code: "sn"},
    {name: "Serbia", code: "rs"},
    {name: "Sierra Leona", code: "sl"},
    {name: "Singapore", code: "sg"},
    {name: "Slovakia", code: "sk"},
    {name: "Somalia", code: "so"},
    {name: "South Africa", code: "za"},
    {name: "South Korea", code: "kr"},
    {name: "Spain", code: "es"},
    {name: "Sri Lanka", code: "lk"},
    {name: "Suriname", code: "sr"},
    {name: "Sweden", code: "se"},
    {name: "Switzerland", code: "ch"},
    {name: "Syria", code: "sy"},
    {name: "Tanzania", code: "tz"},
    {name: "Thailand", code: "th"},
    {name: "Tunisia", code: "tn"},
    {name: "Turkmenistan", code: "tm"},
    {name: "Türkiye", code: "tr"},
    {name: "Uganda", code: "ug"},
    {name: "Ukraine", code: "ua"},
    {name: "United Arab Emirates", code: "ae"},
    {name: "United Kingdom", code: "gb"},
    {name: "Uruguay", code: "uy"},
    {name: "USA", code: "us"},
    {name: "Vatican", code: "va"},
    {name: "Venezuela", code: "ve"},
    {name: "Vietnam", code: "vn"},
    {name: "Yemen", code: "ye"},
    {name: "Zambia", code: "zm"},
    {name: "Zimbabwe", code: "zw"},
];

export const Flag = (props) => (
    <span role="img" onClick={props.clickHandler}>
        <img className={props.className} alt={FLAG_MAP[props.num].name}
            src={images('./' + FLAG_MAP[props.num].code + '.png')} />
    </span>
);

export const Label = (props) => (
    <span role="img" className={props.className} onClick={props.clickHandler}>
        {FLAG_MAP[props.num].name}
    </span>
);