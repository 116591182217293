import React from 'react';

const EMOJI_MAP = [
    0x1F600,
    0x1F602,
    0x1F609,
    0x1F60D,
    0x1F618,
    0x1F917,
    0x1F60E,
    0x1F913,
    0x1F641,
    0x1F62D,
    0x1F631,
    0x1F621,
    0x1F4A9,
    0x1F47B,
    0x1F47E,
    0x1F648,
    0x1F941,
    0x1F4AB,
    0x1F44B,
    0x1F44C,
    0x1F3AC,
    0x1F44D,
    0x1F44E,
    0x1F44F,
    0x1F64F,
    0x1F4AA,
    0x1F476,
    0x1F469,
    0x1F475,
    0x1F645,
    0x1F937,
    0x1F934,
    0x1F470,
    0x1F385,
    0x1F483,
    0x1F3C4,
    0x1F3CA,
    0x1F6B4,
    0x1F436,
    0x1F408,
    0x1F434,
    0x1F42E,
    0x1F437,
    0x1F423,
    0x1F989,
    0x1F422,
    0x1F419,
    0x1F490,
    0x1F340,
    0x1F351,
    0x1F345,
    0x1F346,
    0x1F344,
    0x1F354,
    0x1F37F,
    0x1F366,
    0x1F382,
    0x1F37E,
    0x1F37B,
    0x1F3DD,
    0x1F3E1,
    0x1F3AA,
    0x1F682,
    0x1F692,
    0x1F694,
    0x1F698,
    0x1F6A4,
    0x1F48D,
    0x1F681,
    0x1F680,
    0x1F3A8,
    0x1F3B2,
    0x1F308,
    0x1F383,
    0x1F525,
    0x1F389,
    0x1F381,
    0x1F3C6,
    0x1F407,
    0x1F40C,
    0x26BD,
    0x1F352,
    0x1F353,
    0x1F40D,
    0x1F413,
    0x1F444,
    0x1F4D7,
    0x1F52A,
    0x1F577,
    0x1F691,
    0x1F69B,
    0x1F69C,
    0x1F95A,
    0x1F981,
    0x1F987,
    0x1F988,
    0x1F6CC,
    0x1F6E9,
    0x1F6F5,
    0x1F952,
    0x1F955,
    0x1F98B,
    0x1F31B,
    0x1F327
];

const emoji = (props) => (
    <div role="img" className={props.className} onClick={props.clickHandler}>
        <span>{getEmojiHex(props.num)}</span>
    </div>
);

export function getEmojiHex(num) {
    return String.fromCodePoint(EMOJI_MAP[num]);
}

export default emoji;